import React, { useCallback, useEffect } from 'react';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  defaultNeonatalTemplate,
  defaultTemplate,
  IconType,
  rebuildTooltips,
  StatusBadge,
  StatusIndicator,
  StatusIndicatorStatus,
  SystemMessageTheme,
  Tooltip,
  useCustomTranslation
} from '@holberg/ui-kit';
import cn from 'classnames';
import { DetailsPageHeader } from 'components/DetailsPageHeader';
import {
  ConfirmationTitle,
  FindingsConfirmationModal,
  useConfirmationContext
} from 'components/FindingsConfirmationModal';
import { PatientDetailsHeader } from 'components/PatientDetailsHeader';
import { useReportData } from 'components/ReportSection/useReportData';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { Report } from 'entities/Report.entity';
// import { RealTimeUpdateSendMessages } from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import {
  StudyStatus,
  StudyStatusLabel,
  StudyStatusTheme
} from 'enums/StudyStatus.enum';
import { useStore } from 'hooks/store';
import { useCurrentUserHasLock } from 'hooks/useCurrentUserHasLock';
import { observer } from 'mobx-react-lite';

// import { RTUManager } from 'services/RealTimeUpdatesManager';
import styles from './StudyDetailsHeader.module.scss';

interface Props {
  patientPropertyCodings?: Record<number, CategoricalPropertyCoding[]>;
  patientDetails: PatientDetails;
  reportDetails: Report;
  descriptionDetails: Description;
  studyStatus: StudyStatus;
  saveStatus: StatusIndicatorStatus;
  hasGiveUpSaving?: boolean;
  isExportDisabled?: boolean;
  onRetryAllRequests?: () => void;
  isStudyUnsynced?: boolean;
  isStudyOpenedWarningVisible?: boolean;
}

export const StudyDetailsHeader: React.FC<Props> = observer(
  ({
    patientDetails,
    reportDetails,
    descriptionDetails,
    studyStatus,
    saveStatus,
    isExportDisabled,
    hasGiveUpSaving,
    patientPropertyCodings = {},
    onRetryAllRequests = () => {},
    isStudyUnsynced
  }) => {
    const { t } = useCustomTranslation();

    const confirmationContext = useConfirmationContext();

    const descriptionsStore = useStore(StoreType.Descriptions);
    const studyDetailsStore = useStore(StoreType.StudyDetails);
    const messagesStore = useStore(StoreType.Messages);

    const { reportStartDate } = reportDetails;

    const reportData = useReportData(descriptionDetails.descriptionId);

    const eegType = descriptionDetails.eegType;

    const { currentUserHasLock } = useCurrentUserHasLock();

    useEffect(() => {
      rebuildTooltips();
    }, [isExportDisabled]);

    const onExportClick = useCallback(() => {
      descriptionsStore.completeDescription(
        descriptionDetails.descriptionId,
        {
          data: reportData,
          schema: descriptionDetails.isNeonatal
            ? defaultNeonatalTemplate
            : defaultTemplate
        },
        () => {
          messagesStore.addMessage(
            t('The report was successfully exported!'),
            SystemMessageTheme.Success
          );
        }
      );
    }, [descriptionsStore, messagesStore, reportData, descriptionDetails, t]);

    const onEditReportClick = useCallback(() => {
      confirmationContext?.onOpen({
        title: ConfirmationTitle.Edit_Report,
        submitButtonTitle: t('Yes'),
        onSubmit: () => {
          descriptionsStore.reopenDescription(descriptionDetails.descriptionId);
        }
      });
    }, [descriptionDetails, confirmationContext, descriptionsStore, t]);

    const renderExportButton = useCallback(() => {
      const exportButton = (
        <Button
          data-testid='export-button'
          icon={IconType.Check}
          size={ButtonSize.Regular}
          disabled={
            !currentUserHasLock(descriptionDetails.patientId) ||
            isExportDisabled ||
            isStudyUnsynced
          }
          theme={ButtonTheme.Primary}
          title={t('Complete & export')}
          onClick={onExportClick}
        />
      );

      return isExportDisabled ? (
        <Tooltip
          data={[
            {
              mainTooltip: t('Enter all the required data first')
            }
          ]}
        >
          <div>{exportButton}</div>
        </Tooltip>
      ) : (
        exportButton
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      descriptionDetails.patientId,
      isExportDisabled,
      isStudyUnsynced,
      t,
      onExportClick
    ]);

    return (
      <header
        className={cn(
          styles['study-details-header'],
          isStudyUnsynced && styles['has-warning-msg']
        )}
      >
        <PatientDetailsHeader
          patientDetails={patientDetails}
          patientPropertyCodings={patientPropertyCodings}
        />

        <DetailsPageHeader
          title={reportStartDate}
          actionButton={
            <div className={styles['action-container']}>
              {studyStatus === StudyStatus.Completed ? (
                <Button
                  icon={IconType.Edit}
                  size={ButtonSize.Regular}
                  onClick={onEditReportClick}
                  className={styles['edit-button']}
                  theme={ButtonTheme.Highlighted}
                  title={t('Edit report')}
                />
              ) : (
                renderExportButton()
              )}
            </div>
          }
          descriptors={[
            {
              title: t('EEG type'),
              value: eegType
            },
            {
              title: t('Age at the study time'),
              value:
                descriptionDetails?.patientAges.chronologicalAge?.displayText
                  .eitherValue
            }
          ]}
        >
          <StatusBadge
            className={styles.badge}
            theme={StudyStatusTheme[studyStatus]}
            title={StudyStatusLabel[studyStatus]}
          />
          <StatusIndicator status={saveStatus}>
            {saveStatus === StatusIndicatorStatus.NonRetryError &&
              t('Latest changes weren’t saved')}
            {saveStatus === StatusIndicatorStatus.Error && (
              <>
                {t('Unsaved changes, we’ll retry to save shortly.')}
                {hasGiveUpSaving && (
                  <Button
                    theme={ButtonTheme.Underlined}
                    title={t('Retry')}
                    onClick={onRetryAllRequests}
                    className={cn(styles['retry-container'], styles.retry)}
                  />
                )}
              </>
            )}
          </StatusIndicator>
        </DetailsPageHeader>
        <FindingsConfirmationModal
          onUpdateSettingsConfig={(userSetting) =>
            studyDetailsStore.updateUserSettingsConfig(userSetting)
          }
        />
      </header>
    );
  }
);
