import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Checkbox, Icon, IconType, rebuildTooltips } from '@holberg/ui-kit';
import cn from 'classnames';
import { TooltipTemplate } from 'components/TooltipTemplate';
import { Description } from 'entities/Description.entity';
import { Study } from 'entities/Study.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { RealTimeUpdateSendMessages } from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { RTUManager } from 'services/RealTimeUpdatesManager';

import styles from './Recording.module.scss';

interface Props {
  report: Description | null;
  recording: Study;
  isFocused?: boolean;
  onDoubleClick?: (id: number) => void;
  onSelect: (recording: Study, e?: any) => void;
  isLocked: boolean;
  overlappingReportId?: number | null;
}

export const Recording: React.FC<Props> = ({
  recording,
  onSelect,
  report,
  isFocused,
  isLocked = true,
  overlappingReportId = null
}) => {
  const patientReportsStore = useStore(StoreType.PatientReports);

  const recordingId = recording.studyId;
  const isDraggable =
    !overlappingReportId &&
    recording.isOnline &&
    patientReportsStore.isRecordingSelectable(
      report ? report.descriptionId : null,
      recordingId
    );

  const onBlur = () => {
    patientReportsStore.focusedRecordingIds = [];
  };

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: DraggableTypes.recording,
      canDrag: isDraggable && !isLocked,
      item: {
        start: recording.formattedStudyStart,
        totalTime: recording.totalTime
      },
      end: (_item, monitor) => {
        if (!monitor.didDrop()) {
          patientReportsStore.discardSelections();
        }
      },
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging()
        };
      }
    }),
    [isDraggable, isLocked]
  );

  const recordingSelected =
    patientReportsStore.selectedReportedRecordings.has(recordingId) ||
    patientReportsStore.selectedUnreportedRecordings.has(recordingId);

  useEffect(() => {
    dragPreview(getEmptyImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    rebuildTooltips();
  }, [recording]);

  useEffect(() => {
    if (
      isDragging &&
      !patientReportsStore.selectedReportedRecordings.size &&
      !patientReportsStore.selectedUnreportedRecordings.size
    ) {
      onSelect(recording);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    <div
      onBlur={onBlur}
      className={cn(
        styles['recording-box'],
        isDraggable
          ? styles['enabled-recording']
          : styles['disabled-recording'],
        {
          [styles['selected-recording']]: recordingSelected,
          'focus-visible': isFocused
        }
      )}
    >
      {!isLocked && (
        <Checkbox
          onChange={() => onSelect(recording)}
          checked={recordingSelected}
          disabled={!isDraggable}
        />
      )}
      <div
        ref={drag}
        className={styles['recording-info']}
        onDoubleClick={(e) => {
          e.stopPropagation();
          RTUManager.sendMessage(RealTimeUpdateSendMessages.OpenStudy, {
            recordingId: recordingId,
            descriptionId: report?.descriptionId || null,
            patientId: recording.patientId,
            overlappingRecordingIds: recording.overlappingRecordingIds
          });
        }}
      >
        <span className={styles['recording-date']}>
          {recording.formattedStudyStart}
        </span>
        <Icon iconType={IconType.Arrow} className={styles['arrow-icon']} />
        <span className={styles['recording-time']}>{recording.totalTime}</span>
      </div>
      <TooltipTemplate
        message={
          report?.isCompleted
            ? 'No actions are available for recording in completed report'
            : overlappingReportId
            ? `No actions are available for this recording. An overlapping recording is already added to Report ID: ${overlappingReportId}`
            : null
        }
        tooltipData={[
          [
            {
              title: 'Start',
              value: recording.formattedStudyStartDateTime
            },
            {
              title: 'Stop',
              value: recording.formattedStudyStopDateTime
            }
          ],
          [
            {
              title: 'Total time',
              value: recording.totalTime
            },
            {
              title: 'Recorded time',
              value: recording.totalRecordingTime.eitherValue
            }
          ],
          [{ title: 'ID', value: recordingId.toString() }]
        ]}
        badgeContent={{
          badgeLabel: 'Double-click',
          badgeSubLabel: 'to open the EEG'
        }}
      >
        <Icon
          iconType={IconType.Info}
          className={styles['recording-info-icon']}
        />
      </TooltipTemplate>
    </div>
  );
};
