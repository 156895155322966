import { StatusIndicatorStatus } from '@holberg/ui-kit';
import { AxiosError } from 'axios';
import { debounce } from 'debounce';
import { ApiError } from 'entities/ApiError.entity';
import { Description } from 'entities/Description.entity';
import { EventCode } from 'entities/EventCode.entity';
import { EventCoding } from 'entities/EventCoding.entity';
import { EventPropertyCode } from 'entities/EventPropertyCode.entity';
import { EventPropertyCoding } from 'entities/EventPropertyCoding.entity';
import { EventPropertyCodingsCategorical } from 'entities/EventPropertyCodingsCategorical.entity';
import { EventPropertyCodingsDecimal } from 'entities/EventPropertyCodingsDecimal.entity';
import { EventPropertyCodingsInteger } from 'entities/EventPropertyCodingsInteger.entity';
import { EventPropertyCodingString } from 'entities/EventPropertyCodingString.entity';
import { EventPropertyCodingUpdateDTO } from 'entities/EventPropertyCodingUpdateDTO.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { EventPropertyPanelFocused } from 'entities/EventPropertyPanelFocused.entity';
import { EventPropertyType } from 'entities/EventPropertyType.entity';
import { Operator } from 'entities/Operator.entity';
import { Study } from 'entities/Study.entity';
import { Tab } from 'entities/Tab';
import { UnknownError } from 'entities/UnknownError.entity';
import { SaveOperationType } from 'enums/SaveOperationType.enum';
import { StoreType } from 'enums/StoreType.enum';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';
import { ApiLoadingState } from 'services/API/ApiLoadingState';
import { ScoreApi } from 'services/API/Score/ScoreApi';
import { StudyApi } from 'services/API/Study/StudyApi';
import { RequestsQueueManager } from 'services/RequestsQueueManager';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';
import { SensorState } from 'stores/head-model';

import {
  convertSensorsForm,
  createEmptyEventPropertyCoding,
  getCategoricalCodingsDTO,
  getNumberCodingsDTO,
  getSensorCodingsDTO,
  getStringCodingsDTO,
  reduceNumberPropertyCoding,
  SensorCodings
} from './helpers';

export type NumberPropertyCoding = {
  operatorId: Operator['operatorId'];
  value1: number | null;
  value2: number | null;
};

type CategoricalCoding = {
  [key: string]: boolean | string | undefined;
  radio?: string;
};

export type EventPropertyCodingsData = {
  categorical: {
    [key: number]: CategoricalCoding;
  };
  decimal: {
    [key: number]: NumberPropertyCoding;
  };
  integer: {
    [key: number]: NumberPropertyCoding;
  };
  string: {
    [key: number]: string;
  };
  headModel?: {
    [key: string]: {
      [key: number]: SensorState;
    };
  };
};

export class FindingPropertiesStore implements BaseStore {
  @observable
  propertyPanelsByAgeConstraint!: Map<
    string,
    ReturnType<typeof EventPropertyPanel.deserializeAsMap>
  >;

  @observable
  propertyPanelsLoading!: boolean;

  @observable
  propertyPanelsError?: ApiError | UnknownError;

  @observable
  propertyCodings!: Map<
    EventCoding['eventCodingId'],
    Map<
      EventPropertyPanel['eventPropertyPanelId'],
      ReturnType<typeof EventPropertyCoding.deserialize>
    >
  >;

  @observable
  propertySensors!: Map<
    EventCoding['eventCodingId'],
    Map<EventPropertyPanel['eventPropertyPanelId'], SensorCodings[]>
  >;

  @observable
  propertyCodingsLoading!: boolean;

  @observable
  propertyCodingsError?: ApiError | UnknownError;

  @observable
  private updatePropertiesManagers!: Map<
    EventCoding['eventCodingId'],
    Map<
      EventPropertyPanel['eventPropertyPanelId'],
      RequestsQueueManager<
        {
          studyId: Study['studyId'];
          descriptionId: Description['descriptionId'];
          eventCodingId: EventCoding['eventCodingId'];
          dto: EventPropertyCodingUpdateDTO;
        },
        ReturnType<typeof StudyApi.updateEventPropertyCodings>,
        AxiosError<unknown>
      >
    >
  >;

  @observable
  propertyCodingsUpdateErrors!: Map<
    EventPropertyPanel['eventPropertyPanelId'],
    ApiError | UnknownError | undefined
  >;

  @observable propertyTabs!: Map<
    Study['ageConstraints'],
    Map<EventCode['eventCodeId'], Tab[]>
  >;

  @observable propertyTabsLoading!: boolean;

  @observable propertyTabsError?: ApiError | UnknownError;

  @observable
  readonly apiLoadingState: ApiLoadingState = new ApiLoadingState();

  @observable
  latestSavingPanelId?: EventPropertyPanel['eventPropertyPanelId'];

  // Structure: [tabPositionNumber[panelPositionNumber[groupPositionNumber: itemPositionsLength]]]
  @observable
  propertyIndexStructure?: Array<Array<Array<number>>>;

  @observable
  focusedProperty!: EventPropertyPanelFocused;

  @observable
  currentFocusedItemInPropertyPanel?: HTMLElement;

  constructor() {
    makeObservable(this);
    this.reset();
  }

  @action reset() {
    this.propertyPanelsByAgeConstraint = new Map();
    this.propertyCodingsLoading = false;
    this.propertyPanelsLoading = false;
    this.propertyPanelsError = undefined;
    this.latestSavingPanelId = undefined;
    this.propertyIndexStructure = [];
    this.focusedProperty = {};
    this.propertyCodings = new Map();
    this.propertySensors = new Map();
    this.propertyCodingsError = undefined;
    this.propertyCodingsUpdateErrors = new Map();
    this.propertyTabs = new Map();
    this.propertyTabsLoading = false;
    this.propertyTabsError = undefined;
    this.updatePropertiesManagers = new Map();
    this.currentFocusedItemInPropertyPanel = undefined;
    this.apiLoadingState.reset();
  }

  private createPropertiesManager() {
    const updatePropertiesManager = new RequestsQueueManager<
      {
        studyId: Study['studyId'];
        descriptionId: Description['descriptionId'];
        eventCodingId: EventCoding['eventCodingId'];
        dto: EventPropertyCodingUpdateDTO;
      },
      ReturnType<typeof StudyApi.updateEventPropertyCodings>,
      AxiosError<unknown>
    >(
      (data) =>
        StudyApi.updateEventPropertyCodings(
          data.descriptionId,
          data.eventCodingId,
          data.dto
        ),
      (requestQueue, newRequest) => {
        return [
          ...requestQueue.slice(0, requestQueue.length - 1),
          {
            ...requestQueue[requestQueue.length - 1],
            data: newRequest.data
          }
        ];
      }
    );
    updatePropertiesManager.callAction = debounce(
      updatePropertiesManager.callAction,
      1000
    );

    return updatePropertiesManager;
  }

  private getPropertiesManager(
    eventCodingId: EventCoding['eventCodingId'],
    panelId: EventPropertyPanel['eventPropertyPanelId']
  ) {
    if (!this.updatePropertiesManagers.has(eventCodingId)) {
      this.updatePropertiesManagers.set(eventCodingId, new Map());
    }

    if (this.updatePropertiesManagers.get(eventCodingId)!.has(panelId)) {
      return this.updatePropertiesManagers.get(eventCodingId)!.get(panelId)!;
    }

    const updatePropertiesManager = this.createPropertiesManager();
    this.updatePropertiesManagers
      .get(eventCodingId)!
      .set(panelId, updatePropertiesManager);

    return updatePropertiesManager;
  }

  @action clearErrors() {
    this.propertyPanelsError = undefined;
    this.propertyCodingsError = undefined;
    this.propertyTabsError = undefined;

    this.propertyCodingsUpdateErrors.clear();
  }

  @computed
  get saveStatus(): StatusIndicatorStatus {
    if (this.apiLoadingState.isAnyLoading) {
      return StatusIndicatorStatus.Loading;
    }

    if (
      this.latestSavingPanelId &&
      this.propertyCodingsUpdateErrors.get(this.latestSavingPanelId)
    ) {
      return StatusIndicatorStatus.NonRetryError;
    }

    return StatusIndicatorStatus.Saved;
  }

  @action
  clearAllRequests() {
    this.apiLoadingState.reset();
  }

  getPanel(
    ageConstraint: string,
    panelId: EventPropertyPanel['eventPropertyPanelId']
  ): EventPropertyPanel | undefined {
    return this.propertyPanelsByAgeConstraint.get(ageConstraint)?.get(panelId);
  }

  @action
  setCurrentFocusedItem(element: HTMLElement) {
    this.currentFocusedItemInPropertyPanel = element;
  }

  @action
  async loadPropertyPanels(
    eventCodeId: number,
    panelIds: number[],
    ageConstraint: string,
    includeInActive?: boolean
  ) {
    this.propertyPanelsLoading = true;
    this.propertyPanelsError = undefined;

    try {
      const { data } = await ScoreApi.loadEventPropertyPanels(
        eventCodeId,
        panelIds,
        ageConstraint,
        includeInActive
      );

      if (!this.propertyPanelsByAgeConstraint.has(ageConstraint)) {
        this.propertyPanelsByAgeConstraint.set(ageConstraint, new Map());
      }

      const panelsByAgeConstraint = this.propertyPanelsByAgeConstraint.get(
        ageConstraint
      )!;

      runInAction(() => {
        const panels = EventPropertyPanel.deserializeAsList(data);
        panels.forEach((panel) =>
          panelsByAgeConstraint.set(panel.eventPropertyPanelId, panel)
        );
      });
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('property-panels', error);
      this.propertyPanelsError = error;
    } finally {
      this.propertyPanelsLoading = false;
    }
  }

  @action
  async loadPropertyCodings(
    descriptionId: Description['descriptionId'],
    eventCodingId: EventCoding['eventCodingId']
  ) {
    this.propertyCodingsLoading = true;
    this.propertyCodingsError = undefined;

    try {
      const { data } = await StudyApi.loadEventPropertyCodings(
        descriptionId,
        eventCodingId
      );

      const panelsMap = new Map<
        EventPropertyPanel['eventPropertyPanelId'],
        EventPropertyCoding
      >();
      Object.entries(EventPropertyCoding.deserialize(data)).forEach(
        ([key, value]) => {
          value.forEach(
            (
              propertyCoding:
                | EventPropertyCodingsDecimal
                | EventPropertyCodingsInteger
                | EventPropertyCodingString
                | EventPropertyCodingsCategorical
            ) => {
              const panel = panelsMap.get(propertyCoding.panelId);
              if (!panel) {
                panelsMap.set(
                  propertyCoding.panelId,
                  createEmptyEventPropertyCoding()
                );
              }
              panelsMap.get(propertyCoding.panelId)![key].push(propertyCoding);
            }
          );
        }
      );

      this.propertyCodings.set(eventCodingId, panelsMap);
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('property-codings', error);
      this.propertyCodingsError = error;
    } finally {
      this.propertyCodingsLoading = false;
    }
  }

  @computed
  get radioButtons() {
    const radioButtonsMap = new Map<
      string,
      Map<
        EventPropertyPanel['eventPropertyPanelId'],
        Map<EventPropertyType['eventPropertyTypeId'], EventPropertyCode[]>
      >
    >();
    this.propertyPanelsByAgeConstraint.forEach((panelsMap, ageConstraint) => {
      const panels = new Map();
      panelsMap.forEach((panel) =>
        panels.set(panel.eventPropertyPanelId, this.getRadioButtons(panel))
      );
      radioButtonsMap.set(ageConstraint, panels);
    });

    return radioButtonsMap;
  }

  private getRadioButtons(
    panel: EventPropertyPanel
  ): Map<EventPropertyType['eventPropertyTypeId'], EventPropertyCode[]> {
    const radioButtonsMap = new Map<
      EventPropertyType['eventPropertyTypeId'],
      EventPropertyCode[]
    >();
    panel.categoricalPropertyTypes.forEach(
      ({ propertyType, propertyCodes }) => {
        radioButtonsMap.set(
          propertyType.eventPropertyTypeId,
          propertyType.isMultiselect
            ? propertyCodes.filter(
                (propertyCode) => propertyCode.isExclusiveInMultiSelect
              )
            : [...propertyCodes]
        );
      }
    );

    return radioButtonsMap;
  }

  private getRadioCode(
    codings: EventPropertyCodingsCategorical[],
    code: EventPropertyCode
  ) {
    const selectedCodingId = codings.find(
      (coding) => coding.propertyCodeId === code.eventPropertyCodeId
    )?.propertyCodeId;
    return !!selectedCodingId ? `${selectedCodingId}` : '';
  }

  private getRadioSelectCode(
    codings: EventPropertyCodingsCategorical[],
    propertyTypeId: EventPropertyType['eventPropertyTypeId']
  ) {
    const selectedCodingId = codings.find(
      (coding) => coding.propertyTypeId === propertyTypeId
    )?.propertyCodeId;
    return !!selectedCodingId ? `${selectedCodingId}` : '';
  }

  private getCategoricalCodes(
    panel: EventPropertyPanel,
    categoricalPropertyCodings: EventPropertyCodingsCategorical[]
  ) {
    const categorical = panel.categoricalPropertyTypes.reduce<
      EventPropertyCodingsData['categorical']
    >((acc, property) => {
      const categoricalCodings = property.propertyType.isMultiselect
        ? property.propertyCodes.reduce<CategoricalCoding>(
            (codesAcc, code) =>
              code.isExclusiveInMultiSelect || code.isNotScoredProperty
                ? {
                    ...codesAcc,
                    radio:
                      codesAcc.radio ||
                      this.getRadioCode(categoricalPropertyCodings, code)
                  }
                : {
                    ...codesAcc,
                    [code.eventPropertyCodeId]: !!categoricalPropertyCodings.find(
                      (coding) =>
                        coding.propertyCodeId === code.eventPropertyCodeId
                    )
                  },
            {}
          )
        : {
            radio: this.getRadioSelectCode(
              categoricalPropertyCodings,
              property.propertyType.eventPropertyTypeId
            )
          };

      if (!Object.values(categoricalCodings).some((value) => !!value)) {
        const notScored = property.propertyCodes.find(
          (code) => code.isNotScoredProperty
        );
        if (notScored) {
          return {
            ...acc,
            [property.propertyType.eventPropertyTypeId]: {
              radio: `${notScored.eventPropertyCodeId}`
            }
          };
        }
      }

      return {
        ...acc,
        [property.propertyType.eventPropertyTypeId]: categoricalCodings
      };
    }, {});

    return categorical;
  }

  @action setSensors(
    eventCodingId: EventCoding['eventCodingId'],
    panelId: EventPropertyPanel['eventPropertyPanelId'],
    sensors: SensorCodings[]
  ) {
    if (!this.propertySensors.has(eventCodingId)) {
      this.propertySensors.set(eventCodingId, new Map());
    }

    this.propertySensors.get(eventCodingId)!.set(panelId, sensors);
  }

  @action setPropertyIndexStructure(
    propertyIndexStructure: Array<Array<Array<number>>>
  ) {
    this.propertyIndexStructure = propertyIndexStructure;
  }

  @action setFocusedProperty(data: {
    tabPositionNumber?: number;
    panelPositionNumber?: number;
    groupPositionNumber?: number;
    itemPositionNumber?: number;
  }) {
    this.focusedProperty = {
      ...data
    };
  }

  @action getFirstGroupIndex = (
    tabPositionNumber?: number,
    panelPositionNumber?: number
  ) =>
    tabPositionNumber !== undefined && panelPositionNumber !== undefined
      ? Number(
          Object.keys(
            this.propertyIndexStructure?.[tabPositionNumber]?.[
              panelPositionNumber
            ] || {}
          )[0] || 0
        )
      : 0;

  @action updateFocusedProperty(action: 'down' | 'up' | 'tab') {
    const {
      tabPositionNumber,
      panelPositionNumber,
      groupPositionNumber,
      itemPositionNumber
    } = this.focusedProperty;

    if (action === 'down') {
      const isLastItem =
        Number.isInteger(tabPositionNumber) &&
        Number.isInteger(panelPositionNumber) &&
        Number.isInteger(groupPositionNumber) &&
        (itemPositionNumber || 0) + 1 ===
          this.propertyIndexStructure?.[tabPositionNumber!]?.[
            panelPositionNumber!
          ]?.[groupPositionNumber!];

      const isLastGroup =
        Number.isInteger(tabPositionNumber) &&
        Number.isInteger(panelPositionNumber) &&
        groupPositionNumber ===
          Number(
            Object.keys(
              this.propertyIndexStructure?.[tabPositionNumber!]?.[
                panelPositionNumber!
              ] || {}
            ).slice(-1)[0]
          );

      this.setFocusedProperty({
        tabPositionNumber,
        panelPositionNumber,
        groupPositionNumber:
          groupPositionNumber === undefined || (isLastItem && isLastGroup)
            ? -1
            : groupPositionNumber === -1
            ? this.getFirstGroupIndex(tabPositionNumber, panelPositionNumber)
            : isLastItem
            ? groupPositionNumber + 1
            : groupPositionNumber,
        itemPositionNumber:
          itemPositionNumber === undefined ||
          isLastItem ||
          groupPositionNumber === -1
            ? 0
            : itemPositionNumber + 1
      });
    }

    if (action === 'up') {
      const currentGroups =
        Number.isInteger(tabPositionNumber) &&
        Number.isInteger(panelPositionNumber) &&
        Number.isInteger(groupPositionNumber)
          ? Object.keys(
              this.propertyIndexStructure?.[tabPositionNumber!][
                panelPositionNumber!
              ] || {}
            )
          : ['0'];

      const prevGroup = currentGroups
        ? currentGroups.indexOf(String(groupPositionNumber)) === 0
          ? -1
          : groupPositionNumber === -1
          ? currentGroups.slice(-1)[0]
          : currentGroups[
              currentGroups.indexOf(String(groupPositionNumber)) - 1
            ]
        : 0;

      this.setFocusedProperty({
        tabPositionNumber,
        panelPositionNumber,
        groupPositionNumber:
          (itemPositionNumber || 0) <= 0
            ? Number(prevGroup)
            : groupPositionNumber,
        itemPositionNumber:
          itemPositionNumber === undefined
            ? 0
            : (itemPositionNumber || 0) <= 0
            ? Number.isInteger(tabPositionNumber) &&
              Number.isInteger(panelPositionNumber) &&
              prevGroup
              ? this.propertyIndexStructure?.[tabPositionNumber!][
                  panelPositionNumber!
                ][prevGroup] - 1
              : 0
            : itemPositionNumber - 1
      });
    }

    if (action === 'tab') {
      const isLastTab =
        Number.isInteger(tabPositionNumber) &&
        tabPositionNumber! + 1 ===
          Object.keys(this.propertyIndexStructure || {}).length;

      const isLastPanel =
        Number.isInteger(tabPositionNumber) &&
        Number.isInteger(panelPositionNumber) &&
        panelPositionNumber! + 1 ===
          Object.keys(this.propertyIndexStructure?.[tabPositionNumber!] || {})
            .length;

      const nextPanel =
        panelPositionNumber === undefined || isLastPanel
          ? 0
          : panelPositionNumber + 1;

      const nextTab =
        tabPositionNumber !== undefined
          ? isLastPanel
            ? isLastTab
              ? 0
              : tabPositionNumber + 1
            : tabPositionNumber
          : 0;

      this.setFocusedProperty({
        tabPositionNumber: nextTab,
        panelPositionNumber: nextPanel,
        groupPositionNumber: this.getFirstGroupIndex(nextTab, nextPanel),
        itemPositionNumber: 0
      });
    }
  }

  getPropertyCodings(
    ageConstraint: string,
    eventCodingId: EventCoding['eventCodingId'],
    panelId: EventPropertyPanel['eventPropertyPanelId']
  ): EventPropertyCodingsData | null {
    const propertyCodingsByEventCoding = this.propertyCodings.get(
      eventCodingId
    );
    const panel = this.getPanel(ageConstraint, panelId);

    if (panel) {
      const propertyCodings =
        propertyCodingsByEventCoding?.get(panelId) ||
        createEmptyEventPropertyCoding();

      const categorical = this.getCategoricalCodes(
        panel,
        propertyCodings.categorical
      );

      const decimal: EventPropertyCodingsData['decimal'] = propertyCodings.decimal.reduce(
        reduceNumberPropertyCoding,
        {}
      );

      const integer: EventPropertyCodingsData['integer'] = propertyCodings.integer.reduce(
        reduceNumberPropertyCoding,
        {}
      );

      const string: EventPropertyCodingsData['string'] = propertyCodings?.string.reduce(
        (acc, el) => ({ ...acc, [el.propertyTypeId]: el.freetext }),
        {}
      );

      const headModel: EventPropertyCodingsData['headModel'] = this.propertySensors
        .get(eventCodingId)
        ?.get(panelId)
        ?.reduce<EventPropertyCodingsData['headModel']>(
          (acc, sensorCoding) => ({
            ...acc,
            [sensorCoding.locationType]: {
              ...acc?.[sensorCoding.locationType],
              [sensorCoding.sensorId]: sensorCoding.state
            }
          }),
          {}
        );

      return { categorical, decimal, integer, string, headModel };
    } else {
      return null;
    }
  }

  @action async updatePropertyCodings({
    studyId,
    descriptionId,
    eventCodingId,
    panel,
    data
  }: {
    studyId: Study['studyId'];
    descriptionId: Description['descriptionId'];
    eventCodingId: EventCoding['eventCodingId'];
    panel: EventPropertyPanel;
    data: EventPropertyCodingsData;
  }) {
    const saveIdentifier = `${SaveOperationType.SAVE}_${studyId}_${eventCodingId}_${panel.eventPropertyPanelId}`;
    this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, true);
    this.latestSavingPanelId = panel.eventPropertyPanelId;

    const headModelPropertyTypeId =
      panel.headModelPropertyType?.propertyType.eventPropertyTypeId;

    try {
      const categoricalPropertyCodings = getCategoricalCodingsDTO(
        data.categorical
      );
      const integerPropertyCodings = getNumberCodingsDTO(data.integer);
      const decimalPropertyCodings = getNumberCodingsDTO(data.decimal);
      const stringPropertyCodings = getStringCodingsDTO(data.string);
      const locationSensorCodings = headModelPropertyTypeId
        ? getSensorCodingsDTO(data.headModel, headModelPropertyTypeId)
        : [];

      const dto = EventPropertyCodingUpdateDTO.deserialize({
        panelId: panel.eventPropertyPanelId,
        categoricalPropertyCodings,
        integerPropertyCodings,
        decimalPropertyCodings,
        stringPropertyCodings,
        locationSensorCodings
      });

      const updatePropertiesManager = this.getPropertiesManager(
        eventCodingId,
        panel.eventPropertyPanelId
      );

      await updatePropertiesManager.callAction({
        data: {
          studyId,
          descriptionId,
          eventCodingId,
          dto
        },
        onSuccess: ({ data: responseData }) => {
          this.propertyCodingsUpdateErrors.set(
            panel.eventPropertyPanelId,
            undefined
          );
          this.propertyCodings.get(eventCodingId)?.set(
            panel.eventPropertyPanelId,
            EventPropertyCoding.deserialize({
              categorical: responseData.categoricalPropertyCodings,
              string: responseData.stringPropertyCodings,
              integer: responseData.integerPropertyCodings,
              decimal: responseData.decimalPropertyCodings
            })
          );

          this.setSensors(
            eventCodingId,
            panel.eventPropertyPanelId,
            convertSensorsForm(data.headModel)
          );

          stores[StoreType.Messages].clearSystemMessages();
          this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);

          stores[StoreType.Findings].loadReportHeadModel(descriptionId);

          stores[StoreType.Findings].loadShoppingCarts(descriptionId, [
            eventCodingId
          ]);

          stores[StoreType.Findings].setEventCoding(
            descriptionId,
            responseData.eventCoding
          );
        },
        onFail: (e) => {
          const error = ApiError.deserializeFromCatch(e);
          this.propertyCodingsUpdateErrors.set(
            panel.eventPropertyPanelId,
            error
          );
          this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);

          stores[StoreType.Messages].addMsgError(saveIdentifier, error);
        }
      });
    } catch (err) {
      const error = ApiError.deserializeFromCatch(err);
      stores[StoreType.Messages].addMsgError(saveIdentifier, error);
      this.apiLoadingState.setRequestLoadingStatus(saveIdentifier, false);
    }
  }

  @action
  async loadPropertyTabs(
    eventCodeId: EventCode['eventCodeId'],
    ageConstraint: Study['ageConstraints'],
    includeInActive?: boolean
  ) {
    if (this.propertyTabs.get(ageConstraint)?.get(eventCodeId)) return;

    this.propertyTabsLoading = true;
    this.propertyTabsError = undefined;

    try {
      const { data } = await ScoreApi.loadEventPropertyTabs(
        eventCodeId,
        ageConstraint,
        includeInActive
      );

      if (!this.propertyTabs.has(ageConstraint)) {
        this.propertyTabs.set(ageConstraint, new Map());
      }

      this.propertyTabs
        .get(ageConstraint)!
        .set(eventCodeId, Tab.deserializeAsList(data));
    } catch (e) {
      const error = ApiError.deserializeFromCatch(e);
      stores[StoreType.Messages].addMsgError('property-tabs', error);
      this.propertyTabsError = error;
    } finally {
      this.propertyTabsLoading = false;
    }
  }

  getPanelIds(
    eventCodeId: EventCode['eventCodeId'],
    ageConstraint: Study['ageConstraints']
  ): Tab['panelIds'] {
    return (
      this.propertyTabs
        .get(ageConstraint)
        ?.get(eventCodeId)
        ?.reduce<Tab['panelIds']>((acc, item) => {
          acc.push(...item.panelIds);
          return acc;
        }, []) || []
    );
  }

  @action async loadPropertiesData(
    eventCodeId: EventCode['eventCodeId'],
    ageConstraint: Study['ageConstraints'],
    includeInActive?: boolean
  ) {
    await this.loadPropertyTabs(eventCodeId, ageConstraint, includeInActive);
    if (this.propertyTabsError) {
      return;
    }

    const panelIds = this.getPanelIds(eventCodeId, ageConstraint);
    this.loadPropertyPanels(
      eventCodeId,
      panelIds,
      ageConstraint,
      includeInActive
    );
  }
}
