import { generatePath } from 'react-router-dom';
import { SystemMessageTheme } from '@holberg/ui-kit';
import { Description } from 'entities/Description.entity';
import { Event } from 'entities/Event.entity';
import { RTUConfig } from 'entities/RTUConfig.entity';
import { Study } from 'entities/Study.entity';
import {
  RealTimeUpdateReceiveMessages,
  RealTimeUpdateSendMessages
} from 'enums/RealTimeUpdateType.enum';
import { Routes } from 'enums/Routes.enum';
import { StoreType } from 'enums/StoreType.enum';
import { action, makeObservable, observable } from 'mobx';
import { history } from 'services/history';
import {
  NotificationRTUData,
  OpenStudyRTUData,
  RTUManager,
  StudyRTUData
} from 'services/RealTimeUpdatesManager';
import { SessionStorage } from 'services/SessionStorage';
import { stores } from 'stores';
import { BaseStore } from 'stores/BaseStore';
export class RealTimeUpdatesStore implements BaseStore {
  @observable
  realTimeUpdatesConfig!: ReturnType<typeof RTUConfig.deserialize>;

  constructor() {
    makeObservable(this);
    this.reset();

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.ReaderDisconnected,
        callback: ({ isTerminated }) =>
          this.updateRTUConfig({
            activeStudyIds: [],
            isReaderConnected: false,
            isTerminated
          })
      },
      {
        message: RealTimeUpdateReceiveMessages.ReaderAdminDisconnected,
        callback: () =>
          this.updateRTUConfig({
            isReaderAdminConnected: false
          })
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.CloseStudy,
        callback: (data) => this.closeStudy(data)
      },
      {
        message: RealTimeUpdateReceiveMessages.StudyUpdated,
        callback: (data) => this.updateActiveStudyIds(data.studyId)
      },
      {
        message: RealTimeUpdateReceiveMessages.ShowNotification,
        callback: (data) => this.showNotification(data as NotificationRTUData)
      }
    ]);

    RTUManager.addObservers([
      {
        message: RealTimeUpdateReceiveMessages.OpenStudy,
        callback: (data) => this.openStudy(data)
      }
    ]);
  }

  isReviewSessionActive(studyId: Study['studyId']): boolean {
    return (this.realTimeUpdatesConfig.activeStudyIds || []).some(
      (id) => id === studyId
    );
  }

  @action
  private closeStudy(data: StudyRTUData) {
    const activeStudyIds = (
      this.realTimeUpdatesConfig.activeStudyIds || []
    ).filter((id) => id !== data.studyId);

    this.updateRTUConfig({
      activeStudyIds
    });
  }

  @action
  private openStudy(data: OpenStudyRTUData) {
    const {
      recordingId,
      descriptionId,
      patientId,
      overlappingRecordingIds
    } = data;
    this.updateActiveStudyIds(recordingId);
    if (!descriptionId) {
      if (
        history.location.pathname.includes('/findings') &&
        overlappingRecordingIds?.some((id) => {
          return stores[StoreType.PatientReports].getReportByRecording(id)
            ? true
            : false;
        })
      ) {
        // if description id is null, user is in findings page and there is an overlapping recording, do nothing.
        return;
      }

      //if descriptionId is null,navigate user to patient overview page
      stores[StoreType.PatientReports].lastOpenedUnreportedRecording = data;
      history.push(
        generatePath(Routes.PatientOverview, { id: String(patientId) })
      );
    } else if (descriptionId) {
      history.push(
        generatePath(Routes.StudyFindings, { id: String(descriptionId) })
      );
    }
  }

  @action
  private updateActiveStudyIds(studyId: Study['studyId']) {
    if (!this.isReviewSessionActive(studyId)) {
      const activeStudyIds = this.realTimeUpdatesConfig.activeStudyIds || [];

      this.updateRTUConfig({
        activeStudyIds: [...activeStudyIds, studyId]
      });
    }
  }

  @action
  showNotification(data: NotificationRTUData) {
    stores[StoreType.Messages].addMessage(
      data.message.primaryValue || data.message.secondaryValue || '',
      Object.values(SystemMessageTheme)[data.type]
    );
  }

  @action
  activateEvent(
    studyId: Study['studyId'],
    descriptionId: Description['descriptionId'],
    eventId: Event['eventId']
  ) {
    this.updateRTUConfig({
      activeEventId: eventId
    });

    RTUManager.sendMessage(RealTimeUpdateSendMessages.ActivateEvent, {
      studyId,
      descriptionId,
      eventId
    });
  }

  @action reset() {
    this.realTimeUpdatesConfig = new RTUConfig();
  }

  @action
  async loadRTUConfig() {
    try {
      const storageState = await SessionStorage.getRTUConfig();

      this.realTimeUpdatesConfig = storageState || RTUConfig.deserialize({});
    } catch (e) {}
  }

  @action
  async updateRTUConfig(config: RTUConfig) {
    try {
      const activeStudyIds = (
        config.activeStudyIds ||
        this.realTimeUpdatesConfig.activeStudyIds ||
        []
      ).slice();
      const rtuConfig = RTUConfig.deserialize({
        ...this.realTimeUpdatesConfig,
        ...config,
        activeStudyIds
      });

      await SessionStorage.updateRTUConfig(rtuConfig);

      this.realTimeUpdatesConfig = rtuConfig;
    } catch (e) {}
  }
}
