import React, { useCallback, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { useParams } from 'react-router-dom';
import { GeneralError, IconType, useCustomTranslation } from '@holberg/ui-kit';
import { Recording } from 'components/Recording';
import { Study } from 'entities/Study.entity';
import { DraggableTypes } from 'enums/DraggableTypes.enum';
import { RealTimeUpdateSendMessages } from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { useStore } from 'hooks/store';
import { observer } from 'mobx-react-lite';
import { OpenStudyRTUData } from 'services/RealTimeUpdatesManager';

import styles from './UnreportedRecordings.module.scss';

interface Props {
  recordings: Study[];
  moveRecordings: () => void;
  userHaveEditLock: boolean;
}

export const UnreportedRecordings: React.FC<Props> = observer(
  ({ recordings, moveRecordings, userHaveEditLock = false }) => {
    const { t } = useCustomTranslation();
    const params = useParams<{ id: string }>(),
      patientId = parseInt(params.id);

    const patientReportsStore = useStore(StoreType.PatientReports);
    const patientLockDetailsStore = useStore(StoreType.PatientLockDetails);
    const patientLockDetails = patientLockDetailsStore.patientLockDetails.get(
      patientId
    );
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
      accept: [DraggableTypes.recording],
      canDrop: () => !patientReportsStore.selectedUnreportedRecordings.size,
      drop: (_item: any, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        moveRecordings();
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }));

    useEffect(() => {
      (async () => {
        if (patientReportsStore.lastOpenedUnreportedRecording) {
          await onOpenStudySignal(
            patientReportsStore.lastOpenedUnreportedRecording
          );
          patientReportsStore.lastOpenedUnreportedRecording = null;
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientReportsStore.lastOpenedUnreportedRecording]);

    const onOpenStudySignal = useCallback(
      async (data: OpenStudyRTUData) => {
        if (patientLockDetails?.isLocked && !userHaveEditLock) {
          //if other user has edit lock of this patient
          return;
        }
        if (
          patientReportsStore.getOverlappingReportId([
            ...data.overlappingRecordingIds
          ]) ||
          !patientReportsStore.isRecordingSelectable(null, data.recordingId)
        ) {
          //if overlapping recording is already reported or overlapping unreported recording is already selected
          patientReportsStore.focusedRecordingIds = [data.recordingId];
          return;
        }
        await patientLockDetailsStore.sendPatientLockMessage(
          patientId,
          RealTimeUpdateSendMessages.RequestPatientEditLock
        );
        patientReportsStore.selectedUnreportedRecordings.set(
          data.recordingId,
          patientReportsStore.unreportedRecordings.get(data.recordingId)!
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [patientId, patientLockDetails?.isLocked, userHaveEditLock]
    );

    return (
      <div
        className={styles['unreported-recordings-section']}
        ref={drop}
        style={{ borderColor: isOver && canDrop ? '#2f75ff' : '' }}
        data-testid='unreported-recordings'
      >
        <p className={styles['section-title']}>{t('Unreported recordings')}</p>
        <div className={styles['unreported-recordings']}>
          {recordings.length > 0 ? (
            <>
              <div className={styles['unreported-recordings-label']}>
                <span>{t('Start')}</span>
                <span>{t('Total time')}</span>
              </div>
              {recordings.map((recording: Study) => {
                return (
                  <Recording
                    isLocked={!userHaveEditLock}
                    key={recording.studyId}
                    report={null}
                    recording={recording}
                    overlappingReportId={patientReportsStore.getOverlappingReportId(
                      recording.overlappingRecordingIds
                    )}
                    isFocused={patientReportsStore.focusedRecordingIds?.includes(
                      recording.studyId
                    )}
                    onSelect={(rec) => {
                      patientReportsStore.toggleUnreportedRecordings(rec);
                    }}
                  />
                );
              })}
            </>
          ) : (
            <div style={{ height: '35vh' }}>
              {!patientReportsStore.patientOverviewLoading && (
                <GeneralError
                  className={styles['general-error']}
                  icon={IconType.Empty}
                  title={t('You have no unreported recordings')}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
