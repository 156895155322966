import { EventCode } from 'entities/EventCode.entity';
import { EventPropertyPanel } from 'entities/EventPropertyPanel.entity';
import { GenericPropertyType } from 'entities/GenericPropertyType.entity';
import { PropertyCodeExtraInfo } from 'entities/PropertyCodeExtraInfo.entity';
import { PropertyTypeCode } from 'entities/PropertyTypeCode.entity';
import { Study } from 'entities/Study.entity';
import { Tab } from 'entities/Tab';
import queryString from 'query-string';
import { ApiBase } from 'services/API/ApiBase';
import { CommonPropertyType } from 'stores/property-type-codes';

class ScoreApi extends ApiBase {
  loadPropertyTypes(propertyTypeUrl: string, queryObject: object = {}) {
    const query = queryString.stringify(queryObject);

    return this.client.get<GenericPropertyType[]>(
      `/${propertyTypeUrl}?${query}`
    );
  }

  loadPropertyTypesCodes(
    propertyType: string | number,
    propertyTypeUrl: string,
    queryObject: object = {}
  ) {
    const query = queryString.stringify(queryObject);

    return this.client.get<PropertyTypeCode[]>(
      `/${propertyTypeUrl}/${propertyType}/codes?${query}`
    );
  }

  loadPropertyTypeCodesExtraInfo(
    propertyTypeId: CommonPropertyType,
    propertyTypeUrl: string
  ) {
    return this.client.get<PropertyCodeExtraInfo[]>(
      `/${propertyTypeUrl}/${propertyTypeId}/codes-extrainfo`
    );
  }

  loadPropertyTypesCodesPath(
    propertyType: string | number,
    propertyTypeUrl: string,
    itemId: string | number,
    queryObject: object = {}
  ) {
    const query = queryString.stringify(queryObject);

    return this.client.get<PropertyTypeCode[]>(
      `/${propertyTypeUrl}/${propertyType}/codes/${itemId}?${query}`
    );
  }

  loadEventCodes(ageConstraint: string, includeInactive: boolean = false) {
    const query = queryString.stringify({ ageConstraint, includeInactive });

    return this.client.get<EventCode[]>(`/EventCodes?${query}`);
  }

  loadEventPropertyPanels(
    eventCodeId: number,
    panelIds: number[],
    ageConstraint: string,
    includeInActive?: boolean
  ) {
    const url = queryString.stringifyUrl(
      {
        url: '/eventPropertyPanels',
        query: {
          eventCodeId,
          panelIds,
          ageConstraint,
          includeInActive
        }
      },
      {
        arrayFormat: 'none'
      }
    );

    return this.client.get<EventPropertyPanel[]>(url);
  }

  loadEventPropertyTabs(
    eventCodeId: EventCode['eventCodeId'],
    ageConstraint: Study['ageConstraints'],
    includeInActive?: boolean
  ) {
    const url = queryString.stringifyUrl({
      url: `/eventCodes/${eventCodeId}/eventPropertyTabs`,
      query: {
        ageConstraint,
        includeInActive
      }
    });

    return this.client.get<Tab[]>(url);
  }
}

const instance = new ScoreApi('/score/api/v1');

export { instance as ScoreApi };
