import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState
} from '@microsoft/signalr';
import { AuthToken } from 'entities/AuthToken.entity';
import { CategoricalPropertyCoding } from 'entities/CategoricalPropertyCoding.entity';
import { Description } from 'entities/Description.entity';
import { EditLockStatus } from 'entities/EditLockStatus.entity';
import { Event } from 'entities/Event.entity';
import { GenericEventTree } from 'entities/GenericEventTree.entity';
import { PatientDetails } from 'entities/PatientDetails.entity';
import { Report } from 'entities/Report.entity';
import { Study } from 'entities/Study.entity';
import {
  RealTimeUpdateReceiveMessages,
  RealTimeUpdateSendMessages
} from 'enums/RealTimeUpdateType.enum';
import { StoreType } from 'enums/StoreType.enum';
import { Env } from 'services/Env';
import { stores } from 'stores';

import { User } from '../entities/User.entity';

type MapReceiveMessageToDataType<
  T extends RealTimeUpdateReceiveMessages
> = T extends RealTimeUpdateReceiveMessages.OpenStudy
  ? OpenStudyRTUData
  : T extends
      | RealTimeUpdateReceiveMessages.CloseStudy
      | RealTimeUpdateReceiveMessages.StudyUpdated
  ? StudyRTUData
  : T extends RealTimeUpdateReceiveMessages.StudyListUpdated
  ? StudyListUpdatedRTUData
  : T extends
      | RealTimeUpdateReceiveMessages.ReaderDisconnected
      | RealTimeUpdateReceiveMessages.ReaderAdminDisconnected
  ? DisconnectRTUData
  : T extends
      | RealTimeUpdateReceiveMessages.ReaderConnected
      | RealTimeUpdateReceiveMessages.UserChanged
  ? UserRTUData
  : T extends RealTimeUpdateReceiveMessages.PatientListUpdated
  ? PatientListRTUData
  : T extends RealTimeUpdateReceiveMessages.PatientUpdated
  ? PatientRTUData
  : T extends RealTimeUpdateReceiveMessages.EventNotFound
  ? EventNotFoundRTUData
  : T extends RealTimeUpdateReceiveMessages.StudyActiveState
  ? StudyActiveStateRTUData
  : T extends RealTimeUpdateReceiveMessages.UpdatePatientLockStatus
  ? UpdatePatientLockStatus
  : T extends RealTimeUpdateReceiveMessages.ReaderAdminConnected
  ? ReaderAdminConnectedRTUData
  : EventRTUData;

type MapSendMessageToDataType<
  T extends RealTimeUpdateSendMessages
> = T extends RealTimeUpdateSendMessages.EventNotFound
  ? EventNotFoundRTUData
  : T extends
      | RealTimeUpdateSendMessages.RequestPatientEditLock
      | RealTimeUpdateSendMessages.ReleasePatientEditLock
      | RealTimeUpdateSendMessages.RefreshPatientEditLock
      | RealTimeUpdateSendMessages.GetPatientLockStatus
      | RealTimeUpdateSendMessages.PatientClosed
      | RealTimeUpdateSendMessages.PatientOpened
  ? PatientLockInputModel
  : T extends
      | RealTimeUpdateSendMessages.RequestStudyEditLock
      | RealTimeUpdateSendMessages.OpenStudy
  ? OpenStudyRTUData
  : EventRTUData;

interface MessageData<T extends RealTimeUpdateSendMessages> {
  data?: MapSendMessageToDataType<T>;
  message: T;
}

interface ObserverData<T extends RealTimeUpdateReceiveMessages> {
  message: T;
  callback: (data: MapReceiveMessageToDataType<T>) => void;
}

export interface StudyListUpdatedRTUData {
  studyListChanges: Report[];
  appId?: number;
}

export interface StudyActiveStateRTUData {
  user: User;
  isEditable: boolean;
  studyStatus: string;
  activeUserId?: number;
  studyId: Study['studyId'];
}

export interface StudyRTUData {
  appId?: number;
  study?: Study;
  studyId: Study['studyId'];
  descriptionId?: Description['descriptionId'] | null;
  patient?: PatientDetails;
  patientPropertyCodings?: CategoricalPropertyCoding[];
}

export interface OpenStudyRTUData {
  appId?: number;
  recordingId: Study['studyId'];
  overlappingRecordingIds: number[];
  descriptionId: Description['descriptionId'] | null;
  patientId: PatientDetails['patientId'];
}

export interface PatientRTUData {
  appId: number;
  patient?: PatientDetails;
  patientId: PatientDetails['patientId'];
  patientPropertyCodings?: CategoricalPropertyCoding[];
}

export interface PatientListRTUData {
  appId: number;
  PatientListChanges: PatientRTUData[];
}

export interface UpdatePatientLockStatus {
  resourceId: number;
  status: EditLockStatus;
}

export interface EventRTUData {
  eventId: Event['eventId'];
  studyId: Study['studyId'];
  descriptionId: Description['descriptionId'];
  appId?: number;
  expiry?: Date;
  findingsTreeChanges?: GenericEventTree;
}

export interface NotificationRTUData {
  message: {
    primaryValue?: string;
    secondaryValue?: string;
  };
  type: number;
  internalEventId?: Event['eventId'];
  studyId?: Study['studyId'];
  appId?: number;
}

export interface EventNotFoundRTUData {
  internalEventId: Event['eventId'];
  studyId: Study['studyId'];
  appId?: number;
}

export interface DisconnectRTUData {
  isTerminated: boolean;
}
export interface UserRTUData {
  appId: number;
  authToken: AuthToken;
}
export interface PatientLockInputModel {
  patientId: number;
}

export interface ReaderAdminConnectedRTUData {
  appId: number;
  authToken: AuthToken;
}

class RealTimeUpdatesManager {
  private connection?: HubConnection;
  private messageQueue: MessageData<RealTimeUpdateSendMessages>[] = [];
  private observers: ObserverData<RealTimeUpdateReceiveMessages>[] = [];

  public async createConnection(token: string) {
    if (
      !this.connection ||
      this.connection?.state === HubConnectionState.Disconnected
    ) {
      this.connection = new HubConnectionBuilder()
        .withUrl(Env.BASE_URL + '/hub', { accessTokenFactory: () => token })
        .withAutomaticReconnect()
        .build();

      try {
        await this.connection.start();
        stores[StoreType.RealTimeUpdates].updateRTUConfig({
          isHubConnected: true
        });
      } catch (e) {
        stores[StoreType.RealTimeUpdates].updateRTUConfig({
          isHubConnected: false
        });
      }

      this.setEventListeners();
      this.messageQueue.forEach(({ message, data }) =>
        this.sendMessage(message, data)
      );
      this.clearMessageQueue();
    }
  }

  private clearMessageQueue() {
    this.messageQueue = [];
  }

  private clearObservers() {
    this.observers = [];
  }

  public async closeConnection(shouldClearObservers: boolean) {
    await this.connection?.stop();
    this.clearMessageQueue();
    shouldClearObservers && this.clearObservers();
  }

  public addObservers<T extends RealTimeUpdateReceiveMessages>(
    observersList: ObserverData<T>[]
  ) {
    observersList.forEach((observer) => this.observers.push(observer));
  }

  private setEventListeners() {
    Object.values(RealTimeUpdateReceiveMessages).forEach((message) => {
      this.connection?.on(message, (data) => {
        this.handleRealTimeUpdate(message, data);
      });
    });
  }

  private handleRealTimeUpdate<T extends RealTimeUpdateReceiveMessages>(
    receivedMessage: T,
    data: MapReceiveMessageToDataType<T>
  ) {
    this.observers.forEach(({ message, callback }) => {
      if (message === receivedMessage) {
        callback(data);
      }
    });
  }

  public sendMessage<T extends RealTimeUpdateSendMessages>(
    message: T,
    data?: MapSendMessageToDataType<T>
  ) {
    if (this.connection?.state === HubConnectionState.Connected) {
      this.connection.send(message, data);
    } else {
      this.messageQueue.push({
        message,
        data
      });
    }
  }

  async sendMessageForResponse<T extends RealTimeUpdateSendMessages>(
    message: T,
    data?: MapSendMessageToDataType<T>
  ) {
    if (this.connection?.state === HubConnectionState.Connected) {
      const response = await this.connection.invoke(message, data);
      return response;
    }
  }
}

export const RTUManager = new RealTimeUpdatesManager();
